import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import HeaderHero from './components/HeaderHero';
import Marquee from './components/Marquee';
import Tokenomics from "./components/Tokenomics";
import WhoWeAre from "./components/WhoWeAre";
import HowToBuy from "./components/HowToBuy";
import CommunitySection from "./components/CommunitySection";
import Footer from "./components/Footer";

function App() {
    const appRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Basic GSAP animation to test functionality
        gsap.to(appRef.current, { opacity: 1, duration: 1 });
    }, []);

    return (
        <div ref={appRef} className="App bg-yellow-100 overflow-x-hidden">
            <HeaderHero />
            <Marquee />
            <WhoWeAre />
            <Tokenomics />
            <Marquee />
            <HowToBuy />
            <CommunitySection />
            <Footer />
        </div>
    );
}

export default App;

import React from 'react';
import gifImage5 from '../assets/ducks/5.gif';
import gifImage6 from '../assets/ducks/6.gif';
import gifImage7 from '../assets/ducks/7.gif';
import gifImage8 from '../assets/ducks/8.gif';


const steps = [
    {
        id: 1,
        title: "Set Up a Solana-Compatible Wallet",
        description: "Download and install a wallet that supports Solana, such as Phantom or Solflare. After installation, create a new wallet and securely back up your recovery phrase.",
        icon: gifImage5, // Replace with actual image path
        class: "rotate-[-3deg]"
    },
    {
        id: 2,
        title: "Fund Your Wallet with SOL",
        description: "Acquire Solana (SOL) tokens from a cryptocurrency exchange like Binance, Coinbase, or Kraken. Transfer the purchased SOL to your newly created wallet address.",
        icon: gifImage6, // Replace with actual image path
        class: "rotate-[2deg]"
    },
    {
        id: 3,
        title: "Connect Your Wallet to Raydium",
        description: "Navigate to Raydium's Swap Page and click on \"Connect Wallet.\" Select your wallet provider (e.g., Phantom or Solflare) and authorize the connection.",
        icon: gifImage7, // Replace with actual image path
        class: "rotate-[-3deg]"
    },
    {
        id: 4,
        title: "Swap SOL for $DUCK",
        description: "On the Raydium Swap interface, select SOL in the \"From\" field and $DUCK in the \"To\" field. Enter the amount of SOL you wish to swap and review the transaction details, including the estimated amount of $DUCK you'll receive. Click \"Swap\" and approve the transaction in your wallet. Once confirmed, your $DUCK tokens will appear in your wallet.",
        icon: gifImage8, // Replace with actual image path
        class: "rotate-[3deg]"
    },
];

const HowToBuy: React.FC = () => {
    return (
        <div className="bg-yellow-300 relative py-44 px-6 md:px-20  tracking-widest font-halo-dek  text-center text-black">
            <h2 className="text-5xl font-extrabold mb-24">HOW TO BUY $DUCK</h2>

            {/* Steps 1-3 */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {steps.map((step) => (
                    <div
                        key={step.id}
                        className={`bg-yellow-400 rounded-lg px-6 pt-8 pb-0 text-left  shadow-lg ${step.class}`}
                    >
                        <div className="flex items-center space-x-2">
                            <span className="text-4xl font-bold text-black">{`0${step.id}`}</span>
                            <h3 className="text-2xl pb-4 font-bold text-black">{step.title}</h3>
                        </div>
                        <p className="text-lg font-medium text-black">{step.description}</p>
                        <img src={step.icon} alt={step.title} className="w-6/12   mt-4 mx-auto"/>
                    </div>
                ))}
            </div>

            <div className=" rotate-[-3deg] h-16 bg-yellow-300 absolute bottom-0 left-[-4%] w-[110%]"></div>
            <div
                className=" rotate-[-3deg] h-16 bg-yellow-200 absolute bottom-[-54px] left-[-4%] w-[110%]"></div>
        </div>
    );
};

export default HowToBuy;

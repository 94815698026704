import React from 'react';
import gifImage4 from "../assets/ducks/4.gif";


const Tokenomics: React.FC = () => {
    return (
        <div
            className="flex flex-col md:flex-row items-center relative bg-yellow-100 pb-8 px-4 mt-16 md:space-y-0 md:space-x-6">
            {/* Left Side - Text Content */}
            <div className="text-center tracking-wide font-halo-dek  text-black space-y-4 w-full">
                <h2 className="text-5xl  font-bold ">TOKENOMICS</h2>
                <div className="text-2xl  font-semibold t0 space-y-1">
                    <p>LP: <span className=" font-normal">BURNED FOREVER</span></p>
                    <p>MINT: <span className=" font-normal">REVOKED</span></p>
                    <p>TAX: <span className="font-normal ">0/0</span></p>
                    <p>TICKER: <span className="font-normal">$DUCK</span></p>
                </div>
                <div
                    className="text-4xl font-bold  flex items-center text-center justify-center space-x-2">
                    <span>1,000,000,000</span>
                </div>
            </div>

            {/* Right Side - Image and Vertical Text */}
            <div className="relative flex w-full items-center">
                <img
                    src={gifImage4}// Replace with your image path // Replace with your image path
                    alt="Pepe Character"
                    className="w-9/12 mx-auto z-10"
                />
                <div className="absolute tracking-wide  font-halo-dek  right-0 transform rotate-90 text-black font-bold text-5xl">
                    $DUCK
                </div>
            </div>
            <div className=" rotate-[-3deg] h-16 bg-yellow-100 absolute bottom-0 left-[-4%] w-[110%]"></div>
            <div
                className=" rotate-[-3deg] h-16 bg-yellow-300 absolute bottom-[-104px] left-[-4%] w-[110%]"></div>
        </div>
    );
};

export default Tokenomics;

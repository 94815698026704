import React from 'react';
import gifImage3 from '../assets/ducks/3.gif';


const WhoWeAre: React.FC = () => {
    return (
        <div
            className="flex flex-col relative md:flex-row items-center justify-center bg-yellow-200 py-28 px-6 md:px-20 space-y-6 md:space-y-0 md:space-x-8 font-halo-dek">
            {/* Left Side - Image with Frame and Tag */}
            <div className="relative">
                <div className="border-8 border-white  relative rounded-[12%] ">
                    <img
                        src={gifImage3}// Replace with your image path
                        alt="DUCK"
                        className="w-full h-full mx-auto"
                    />
                    {/* Frame Style */}
                    <div className="absolute inset-0 border-8 border-white rounded-[10%]"></div>
                </div>
                {/* Tag - About Us */}
                <div
                    className="absolute text-black top-0 left-0 -mt-6 -ml-8 bg-yellow-400 font-halo-dek font-bold px-4 py-1 rounded-full transform -rotate-12">
                    ABOUT US
                </div>
            </div>

            {/* Right Side - Text Content */}
            <div className="text-center  text-black md:text-left space-y-4">
                <h2 className="text-5xl font-extrabold">Who We Are</h2>
                <p className="text-xl tracking-wide  font-medium">
                    We’re $DUCK, the meme token bringing fun and good vibes to Solana. Quirky, bold, and full of
                    personality, $DUCK is here to make every transaction a little more quack-tacular.

                    Whether you're a seasoned trader or just love a good meme, $DUCK is your perfect wingman on the
                    blockchain. Join the flock, spread the quacks, and let’s paddle forward together!
                </p>
            </div>

            <div className=" rotate-[-3deg] h-16 bg-yellow-300 absolute bottom-0 left-[-4%] w-[110%]"></div>
            <div
                className=" rotate-[-3deg] h-16 bg-yellow-100 absolute bottom-[-54px] left-[-4%] w-[110%]"></div>
        </div>
    );
};

export default WhoWeAre;
